import React from "react"
import { graphql, Link as GL } from "gatsby"
import {
  Container,
  Heading,
  Stack,
  Image,
  Text,
  Box,
  OrderedList,
  ListItem,
  UnorderedList,
  Spacer,
  LinkBox,
  LinkOverlay,
  Link,
  Divider,
  Flex,
} from "@chakra-ui/react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES, BLOCKS, MARKS } from "@contentful/rich-text-types"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { IoMdQuote } from "react-icons/io"
import TableOfContents from "../components/blog/table-of-contents"
import { AnimatedUnderlineButton } from "../components/animated-under-line-button"

const crypto = require("crypto")

const ServiceDetail: React.FC<any> = ({ data, pageContext }) => {
  const post = data.allContentfulServices.edges[0].node
  const { next, previous } = pageContext
  const { name, tel } = data.contentfulCompanyProfile

  const options: any = {
    renderMark: {
      [MARKS.BOLD]: (text: string) => (
        <Text as={`span`} fontWeight={"bold"} borderBottom={`dotted 1px`}>
          {text}
        </Text>
      ),
    },
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node: any, children: any) => {
        const targetId = node.data.target.sys.id
        const targetPost = post.content.references.filter(
          (ref: any) => ref.contentful_id === targetId
        )[0]

        return (
          <Stack
            as={LinkBox}
            h={`150px`}
            w={`600px`}
            p={4}
            bg={`blackAlpha.50`}
            direction="row"
            spacing={4}
            _hover={{
              opacity: 0.8,
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
            }}
            boxShadow="0px 0px 10px rgba(0, 0, 0, 0.4)"
          >
            <Image
              src={targetPost.images[0].file.url}
              h={`100%`}
              w={`200px`}
              objectFit={`cover`}
            />
            <Stack w={`100%`}>
              <LinkOverlay as={GL} to={`/properties/${targetPost.slug}`}>
                <Text color={`black`} fontWeight={"bold"} fontSize={16}>
                  {targetPost.propertyName}
                </Text>
              </LinkOverlay>

              <Text fontSize={14}>{targetPost.comment.comment}</Text>
              <Divider />
              <Text color={`blackAlpha.700`} bg={`blackAlpha.200`} p={1} px={4}>
                こちらもあわせて読む
              </Text>
            </Stack>
          </Stack>
        )
      },
      [INLINES.HYPERLINK]: (node: any, children: any) => {
        return (
          <Link color={`#5a746d`} href={node.data.uri} target="_blank">
            {children}
          </Link>
        )
      },
      [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
        return (
          <Text lineHeight={2} fontSize={{ base: 13, lg: 15 }}>
            {children}
          </Text>
        )
      },
      [BLOCKS.HEADING_2]: (node: any, children: any) => {
        const anchor = crypto
          .createHash("md5")
          .update(node.content[0].value)
          .digest("hex")

        return (
          <Heading
            as={`h2`}
            id={anchor}
            lineHeight={2}
            fontSize={{ base: 14, lg: 18 }}
            bg={`blackAlpha.50`}
            p={2}
            px={4}
            color={`gray.900`}
            borderLeft={`4px`}
          >
            {children}
          </Heading>
        )
      },
      [BLOCKS.HEADING_3]: (node: any, children: any) => {
        const anchor = crypto
          .createHash("md5")
          .update(node.content[0].value)
          .digest("hex")

        return (
          <Heading
            as={`h3`}
            id={anchor}
            lineHeight={2}
            fontSize={16}
            px={2}
            py={2}
            color={`gray.900`}
            borderBottom={`1px`}
          >
            {children}
          </Heading>
        )
      },
      [BLOCKS.OL_LIST]: (node: any, children: any) => {
        return (
          <Stack p={0} position={`relative`} align="start" spacing={0}>
            <Box bg={`blackAlpha.700`} color={`white`} px={2}>
              <Text fontWeight={`normal`}>CHECK!</Text>
            </Box>
            <OrderedList
              pl={8}
              spacing={2}
              bg={`blackAlpha.50`}
              py={4}
              w={`100%`}
              fontWeight={`bold`}
            >
              {node.content.map((lt: any) => (
                <ListItem pl={2}>
                  <Text fontSize={{ base: 14, lg: 16 }}>
                    {lt.content[0].content[0].value}
                  </Text>
                </ListItem>
              ))}
            </OrderedList>
          </Stack>
        )
      },
      [BLOCKS.UL_LIST]: (node: any, children: any) => {
        return (
          <Stack p={0} position={`relative`} align="start" spacing={0}>
            <Box bg={`blackAlpha.700`} color={`white`} px={2}>
              <Text>POINT</Text>
            </Box>
            <UnorderedList
              pl={8}
              spacing={2}
              bg={`blackAlpha.50`}
              py={4}
              w={`100%`}
              fontWeight={`bold`}
            >
              {node.content.map((lt: any) => (
                <ListItem pl={2}>{lt.content[0].content[0].value}</ListItem>
              ))}
            </UnorderedList>
          </Stack>
        )
      },
      [BLOCKS.QUOTE]: (node: any, children: any) => {
        return (
          <Stack bg={`blackAlpha.50`} borderLeft={`4px`} p={4}>
            <IoMdQuote />
            <Text color={`gray.600`}>{children}</Text>
          </Stack>
        )
      },
    },
  }

  return (
    <Layout>
      <Seo
        title={post.title}
        description={post.metaDescription.metaDescription}
        image={post.featuredPhoto.file.url}
        isArticle
      />
      <Box h={{ base: "4vh", lg: `10vh` }} backgroundSize="cover"></Box>
      <Container maxW="container.xl" as={`main`} p={0}>
        <Stack
          pb={{ base: 8, lg: 24 }}
          pt={12}
          spacing={8}
          direction={{ base: "column", lg: "row" }}
        >
          <Stack w={{ base: "100%", lg: `70%` }}>
            <Stack
              direction="row"
              align="center"
              pb={{ base: 4, lg: 0 }}
              divider={
                <Text fontSize={36} mx={4} fontWeight={"thin"}>
                  |
                </Text>
              }
            >
              <Stack justify="center" align="center" spacing={0}></Stack>
              <Heading
                as={`h2`}
                fontSize={{ base: 16, lg: 24 }}
                color={`yellow.900`}
              >
                {post.name}
              </Heading>
            </Stack>
            <Image
              src={post.featuredPhoto.file.url}
              h={96}
              objectFit={`cover`}
            />
            <Stack
              display={{ base: "flex", lg: "none" }}
              p={{ base: 4, lg: 0 }}
            >
              <TableOfContents raw={post.content.raw} />
            </Stack>
            <Stack spacing={4} p={{ base: 4, lg: 0 }}>
              {renderRichText(post.content, options)}
            </Stack>
            <Stack direction="row" mb={8} pt={12}>
              <Stack>
                {previous && (
                  <>
                    <Text fontSize={13}>前の記事</Text>
                    <LinkBox as="article">
                      <Box
                        borderLeft={`4px`}
                        w={`300px`}
                        bg={`blackAlpha.50`}
                        p={4}
                        _hover={{ bg: "blackAlpha.100" }}
                      >
                        <LinkOverlay as={GL} to={`/articles/${previous.slug}`}>
                          <Text fontSize={14}>{previous.title}</Text>
                        </LinkOverlay>
                      </Box>
                    </LinkBox>
                  </>
                )}
              </Stack>

              <Spacer />
              <Stack align="end">
                {next && (
                  <>
                    <Text fontSize={13}>次の記事</Text>
                    <LinkBox as="article">
                      <Box
                        w={`300px`}
                        bg={`blackAlpha.50`}
                        p={4}
                        borderRight={`4px`}
                        _hover={{ bg: "blackAlpha.100" }}
                      >
                        <LinkOverlay as={GL} to={`/articles/${next.slug}`}>
                          <Text fontSize={14}>{next.title}</Text>
                        </LinkOverlay>
                      </Box>
                    </LinkBox>
                  </>
                )}
              </Stack>
            </Stack>
          </Stack>
          <Stack
            w={{ base: "100%", lg: `30%` }}
            h={{ base: "100%", lg: `650px` }}
            p={{ base: 8, lg: 0 }}
            spacing={8}
            pt={{ base: 0, lg: 8 }}
            sx={{
              position: "sticky",
              top: "0",
            }}
          >
            <Heading
              w={`100%`}
              fontSize={18}
              borderBottom={`1px`}
              pb={2}
              textAlign={{ base: "center", lg: "start" }}
              borderColor={`gray.200`}
            >
              お問い合わせ
            </Heading>
            <Flex
              justify="center"
              align="center"
              direction={`column`}
              pt={4}
              pb={8}
            >
              <Text mb={6} fontSize={{ base: 14, lg: 16 }}>
                お気軽にご相談ください
              </Text>
              <Text
                color={"blackAlpha.500"}
                fontSize={12}
                fontWeight={"thin"}
                mb={0}
              >
                {name}
              </Text>
              <Text
                fontSize={20}
                fontWeight={`bold`}
                letterSpacing={2.2}
                mb={8}
              >
                <Link href="tel:+81-558-64-8880">{tel}</Link>
              </Text>
              <AnimatedUnderlineButton
                text={"サービスに関するご相談はこちら"}
                fontSize={{ base: 14, lg: 16 }}
                url={"/contact"}
              ></AnimatedUnderlineButton>
            </Flex>
          </Stack>
        </Stack>
      </Container>
    </Layout>
  )
}

export default ServiceDetail

export const query = graphql`
  query ($slug: String!) {
    allContentfulServices(
      filter: { slug: { eq: $slug } }
      ) {
      edges {
        node {
          content {
            raw
          }
          createdAt
          featuredPhoto {
            file {
              url
            }
          }
          slug
          updatedAt
          name
          description {
            description
          }
          metaDescription {
            metaDescription
          }
        }
      }
    }
    contentfulCompanyProfile {
      name
      tel
    }
  }
`
