import React from "react"
import { Box, Button, Stack, Text } from "@chakra-ui/react"
import { motion, useAnimationControls } from "framer-motion"
import { navigate } from "gatsby"

export const AnimatedUnderlineButton: React.FC<{
  text: string
  fontSize: any
  url: string
}> = ({ text, fontSize, url }) => {
  const btnControls = useAnimationControls()
  const borderControls = useAnimationControls()

  return (
    <>
      <Button
        as={motion.button}
        position={`relative`}
        rounded={0}
        fontSize={12}
        animate={btnControls}
        initial={{
          backgroundColor: "#000",
          color: "white",
        }}
        onHoverStart={() => {
          btnControls
            .start({
              backgroundColor: "#f0f0f0",
              color: "#000",
              transition: { duration: 0.3 },
            })
            .then(() => {
              borderControls.start({
                width: "100%",
                transition: { duration: 0.5 },
              })
            })
        }}
        onHoverEnd={() => {
          btnControls
            .start({ backgroundColor: "#000", color: "white" })
            .then(() => {
              borderControls.start({ width: 0 })
            })
        }}
        onClick={() => navigate(url)}
      >
        <Stack>
          <Text fontSize={fontSize}>{text}</Text>
          <Box
            as={motion.div}
            animate={borderControls}
            position={`absolute`}
            borderBottom={`2px`}
            borderColor={`black`}
            zIndex={75}
            bottom={0}
            left={0}
          />
        </Stack>
      </Button>
    </>
  )
}
